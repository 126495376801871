<template>
  <div class="" v-if="pages.length > 0">
    <div class="bg-nosotros bg-cover mb-12" :style="`background-image: url(${page.background_image})`">
      <HeaderPage :textoh1="'NOSOTROS'"> </HeaderPage>
    </div>
    <div class="container mx-auto px-8 py-8">
      <p class="mb-4 title-nosotros">{{page.title}}</p>
      <span v-html="page.description"></span>

    </div>
    <div class="bg-gray">
      <div
        class="container mx-auto px-8 flex justify-center items-center flextoblock"
      >
        <div class="w-1-2">
          <p class="title-nosotros mb-4">¿Donde estamos ubicados?</p>
          <p class="text-nosotros">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab beatae
            consequatur delectus blanditiis cupiditate, saepe nesciunt quos modi
            facere earum, iusto fugiat! Eligendi veritatis ut doloribus sint,
            quis adipisci saepe. Lorem ipsum, dolor sit amet consectetur
            adipisicing elit. Distinctio nulla deserunt autem nemo rerum quia,
            quos aut necessitatibus ex nobis dolor accusantium repellat eligendi
            molestias qua
          </p>
          <div class="container flex justify-center">
            <button
              class="rounded-full py-3 px-4 bg-white box-border button-text-blue"
            >
              CONTÁCTANOS >
            </button>
          </div>
        </div>
        <div class="w-1-2 flex justify-center">
          <img class="map" src="../../../assets/images/maps.svg" alt="" />
        </div>
      </div>
    </div>

    <div  v-if="rrss">
      <RRSS :rrss="rrss"></RRSS>
    </div>

    <Footer class="footer-solid py-10"></Footer>
  </div>
</template>

<script>
import HeaderPage from "@/components/Header/Index.vue";
import RRSS from "@/components/Rrs/Index.vue";
import Footer from "@/components/Footer/Index.vue";

export default {
  components: {
    HeaderPage,
    RRSS,
    Footer,
  },
  data() {
    return {
      pages: [],
      page: {},
      sections: [],
    };
  },
  computed:{
    rrss(){
      return this.sections.find(section => section.type_banner === "section_rrss")
    },
  },
  mounted() {
    this.getPages();
    this.getSections();
  },
  methods: {
    getPages() {
      const loading = this.$vs.loading({
        color: "#0276FF",
      });
      this.$store.dispatch("AdminModule/getPages").then((response) => {
        this.pages = response;
        this.page = this.pages.find((item) => item.page === "about-us");
        loading.close();
      });
    },
    getSections() {
      const loading = this.$vs.loading({
        color: "#0276FF",
      });
      this.$store.dispatch("LandingModule/getSections").then((response) => {
        this.sections = response;
        loading.close();
      });
    },
  },
};
</script>

<style scoped>
.bg-nosotros {
  width: 100%;
  height: 72vh;
}

.bg-gray {
  background-color: #f4f4f4;
  height: 40vh;
  width: 100%;
  display: flex;
  align-items: center;
}

.w-1-2 {
  width: 50%;
}
@media only screen and (max-width: 1368px){
  .map{
    height: 250px!important;    
  }
}

@media only screen and (max-width: 639px) {
  .bg-gray {
    width: auto;
    height: auto;
  }
  .w-1-2 {
    width: 100%;
  }
  .flextoblock {
    display: block;
  }
  .title-nosotros {
    display: flex;
    justify-content: center;
  }
  .bg-nosotros {
    background-image: url(../../../assets/images/bgnosotrosmobile.svg);
    background-position: cover;
    width: 100%;
    height: 72vh;
  }
}
</style>
